import { useStaticQuery, graphql } from "gatsby"
export const useFeaturedTags = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(
          frontmatter: { key: { eq: "tags-list" } }
        ) {
          frontmatter {
            tagList {
              tag
              featured
              translations {
                langKey
                tagDisplay
                longDesc
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}