import { useStaticQuery, graphql } from "gatsby"
export const useLanguageList = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "languages" } } }
        ){
          edges {
            node {
              frontmatter {
                languages {
                  langKey
                  langName
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}