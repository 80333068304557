import { useStaticQuery, graphql } from "gatsby"
export const useSiteDefaults = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "defaults" } } }
        ){
          edges {
            node {
              frontmatter {
                currencyFormat {
                  preText
                  postText
                  currencies {
                    currency
                    thousandSeparator
                    decimalSeparator
                    preIndicator
                    postIndicator
                    conversionRateFromUsd
                    numOfDecimals
                    precision
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}