import { useStaticQuery, graphql } from "gatsby"
export const useBlogRollHome = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___createDate] }
          filter: { frontmatter: { templateKey: { eq: "standard-content" }, homePageControls: { show: { eq: true } } } }
        ) {
          edges {
            node {
              id
              fields {
                pathSlug
                langKey
              }
              frontmatter {
                title
                templateKey
                description
                createDate
                updateDate
                featuredPost
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                homePageControls {
                  show
                  start
                  end
                }
                tags {
                  value
                  label
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}