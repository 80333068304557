import { useStaticQuery, graphql } from "gatsby"
export const useNavbarItems = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "navbar-item-list" } } }
        ) {
          edges {
            node {
              frontmatter {
                isFixedTop
                logo {
                  useImage
                  useSiteName
                  siteName
                  fontSize
                  isBold
                  letterSpacing
                }
                languageSelector {
                  use
                }
                item {
                  align
                  fontSizeWidescreen
                  fontSizeTablet
                  fontSizeMobile
                  isBold
                }
                items {
                  link
                  tags
                  translations {
                    langKey
                    name
                  }
                  subItems {
                    link
                    translations {
                      langKey
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}